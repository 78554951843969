var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    {
      attrs: {
        "grid-width": "1/2",
        pageTitle: "Edit Supplier Settings - " + _vm.form.supplierName,
      },
    },
    [
      _vm.hasLoaded
        ? _c(
            "vx-card",
            [
              _c("label", { staticClass: "text-sm" }, [
                _vm._v("Setting Name*"),
              ]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:25",
                    expression: "'required|max:25'",
                  },
                ],
                staticClass: "w-full",
                attrs: { name: "Name", disabled: "" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c("span", { staticClass: "text-danger text-sm" }, [
                _vm._v(_vm._s(_vm.errors.first("Name"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("label", { staticClass: "text-sm" }, [
                    _vm._v("Setting Description*"),
                  ]),
                  _c("vs-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:500",
                        expression: "'required|max:500'",
                      },
                    ],
                    attrs: { name: "Description" },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("Description"))),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("label", { staticClass: "text-sm" }, [_vm._v("Options")]),
                  _c("v-select", {
                    attrs: {
                      multiple: "",
                      taggable: "",
                      "append-to-body": "",
                      name: "Options",
                    },
                    on: { input: _vm.setSelectedOptions },
                    model: {
                      value: _vm.selectedOptions,
                      callback: function ($$v) {
                        _vm.selectedOptions = $$v
                      },
                      expression: "selectedOptions",
                    },
                  }),
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("Type the new option and press enter"),
                  ]),
                ],
                1
              ),
              _vm.selectedOptions.length > 0
                ? _c(
                    "div",
                    { staticClass: "flex items-center mt-4" },
                    [
                      _c("vs-switch", {
                        model: {
                          value: _vm.form.isOptionsMultiple,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isOptionsMultiple", $$v)
                          },
                          expression: "form.isOptionsMultiple",
                        },
                      }),
                      _c("span", { staticClass: "ml-4" }, [
                        _vm._v("Is Options Multiple:"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedOptions.length > 0
                ? _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Default Value"),
                      ]),
                      !_vm.form.isOptionsMultiple
                        ? _c("v-select", {
                            attrs: {
                              value: _vm.selectedDefaultValue,
                              "append-to-body": "",
                              options: _vm.defaultValueOptions,
                            },
                            on: { input: _vm.setSelectedDefaultValue },
                          })
                        : _vm._e(),
                      _vm.form.isOptionsMultiple
                        ? _c("v-select", {
                            attrs: {
                              value: _vm.selectedDefaultValue,
                              multiple: "",
                              "append-to-body": "",
                              options: _vm.defaultValueOptions,
                            },
                            on: { input: _vm.setSelectedDefaultValue },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedOptions.length === 0
                ? _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Default Value"),
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:500",
                            expression: "'max:500'",
                          },
                        ],
                        staticClass: "w-full",
                        attrs: { name: "Default Value" },
                        model: {
                          value: _vm.form.defaultValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "defaultValue", $$v)
                          },
                          expression: "form.defaultValue",
                        },
                      }),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("Default Value"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "lg:float-left mt-6" }, [
                _c("span", { staticClass: "text-sm text-danger" }, [
                  _vm._v("*Required Field"),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-4 mr-4",
                      attrs: { type: "border", color: "danger" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "vs-button",
                    { staticClass: "mt-4", on: { click: _vm.handleSubmit } },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }